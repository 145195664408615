import request from '@/utils/request';

//根据切割工艺获取板厚,材质id没有用
export async function query(params:{textureId:string,cutmethodId:string}): Promise<any> {
  return request('/yuncut_base_platethick_db/ServerFunc_GetPlateThick_dropdown',{
    method:'POST',
    data:params,
  });
}

export async function queryByCutMethodId(param:any,params?:{eCutmethodId:Array<any>}): Promise<any> {
  return request('/yuncut_base_platethick_db/ServerFunc_GetPlatethick',{
    method:'POST',
    data:{
      // @ts-ignore
      "eCutmethodId": params.eCutmethodId, ...param,
    }
  });
}

export async function queryPlatethick(params:any): Promise<any> {
  return request('/yuncut_base_platethick_db/ServerFunc_GetPlatethick',{
    method:'POST',
    data:params
  });
}

export async function save(params:any): Promise<any> {
  return request('/yuncut_base_platethick_db/ServerFunc_SavePlateThick',{
    method:'POST',
    data:{platethick:[params]}
  });
}

export async function remove(params:{bizId?:string}): Promise<any> {//删除
  return request('/yuncut_base_platethick_db/ServerFunc_DeletePlateThick',{
    method:'POST',
    data:params
  });
}

export async function enable(params:{bizId?:string,isEnable?:boolean}): Promise<any>{
return request('/yuncut_base_platethick_db/ServerFunc_SetPlateThick',{
  method:'POST',
  data:params
})
}

export async function checkIsExist(params:any) {
  return request('/yuncut_base_platethick_db/ServerFunc_isExitsThick_V2', {
    method: 'POST',
    data: params,
  });
}

export async function removeByCutMethodAndPlateThick(params:any) {
  return request('/yuncut_base_cutmethod_db/ServerFunc_DeleteAssociation', {
    method: 'POST',
    data: params,
  });
}
