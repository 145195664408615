export default [
  // {
  //   name: 'test',
  //   icon: 'test',
  //   path: '/test',
  //   component: './test/TestE',
  // },
  {
    name:'claim_print',
    path: '/ClaimList/components/print',
    component: './supplier/ClaimList/components/print',
    hideInMenu :true
  },
  {
    name:'login',
    path: '/login',
    component: './supplier/Login',
    hideInMenu :true
  },
  {
    name: 'labelPrint',
    path: '/label/print',
    component: './supplier/deliver/Print',
    hideInMenu:true
  },
  {
    name: 'deliverPrint',
    path: '/deliver/print',
    component: './supplier/deliver/Print',
    hideInMenu:true
  },
  {
    name: 'orderPrint',
    path: '/order/print',
    component: './supplier/prodOrder/Detail/Print',
    hideInMenu:true
  },
  {
    name: 'prodPlanPrint',
    path: '/prod-plan/print',
    component: './supplier/prodPlan/Print',
    hideInMenu:true
  },
  {
    name: 'prodOrderPrint',
    path: '/prod-order/print',
    component: './supplier/prodOrder/Print',
    hideInMenu:true
  },
  {
    name: 'print',
    path: '/print/steel-plates',
    component: './supplier/materialLibrary/List/Prints',
    hideInMenu: true,
  },
  {
    path: '/',
    component: '../layouts/supplier/SupplierLayout',
    authority: ['ROLE_SUPPLIER'],
    routes: [
          {
            name: 'home',
            icon: 'home',
            path: '/',
            component: './supplier/Overview',
          },
          {
            name: 'prod-plan',
            path: '/prod-plan',
            icon: 'FileSearchOutlined',
            hideChildrenInMenu:true,
            routes: [
              {
                path: '/prod-plan',
                redirect: '/prod-plan/list',
              },
              {
                name: 'list',
                path: '/prod-plan/list',
                icon: 'FileSearchOutlined',
                component: './supplier/prodPlan/List',
              },
              {
                name: 'detail',
                path: '/prod-plan/detail',
                icon: 'FileSearchOutlined',
                component: './supplier/prodPlan/Detail',
                showBack:true,
              },

            ]
          },
          {
            name:'qualityControl',
            path:'/qualityControl',
            icon:'project',
            routes:[
              {
                path:'/qualityControl',
                redirect: '/qualityControl/List'
              },
              {
                icon:'project',
                name:'dataLibrary',
                path:'/qualityControl/List',
                component: './supplier/qualityControl/List',
              },
              {
                name:'dataLibraryDetail',
                path:'/qualityControl/List/detail',
                component: './supplier/qualityControl/List/detail',
                hideInMenu:true,
                showBack:true,
              },
              {
                icon: 'share-alt',
                name:'experienceSharing',
                path:'/qualityControl/experienceSharing',
                component: './supplier/qualityControl/experienceSharing',
              },
              {
                name:'experienceSharingDetail',
                path:'/qualityControl/experienceSharing/detail',
                component: './supplier/qualityControl/experienceSharing/detail',
                hideInMenu:true,
                showBack:true,
              },
              {
                icon: 'play-square',
                name:'videos',
                path:'/qualityControl/videos',
                component: './supplier/qualityControl/videos',
              },
              {
                icon: 'calendar',
                name: 'train',
                path: '/qualityControl/train',
                component: './supplier/qualityControl/train',
              },
              {
                name: 'train-detail',
                path: '/qualityControl/train/detail',
                component: './supplier/qualityControl/train/detail/index',
                hideInMenu:true,
                showBack:true,
              },
              {
                icon: 'check-square',
                name: 'spotCheck',
                path: '/qualityControl/spotCheck',
                component: './supplier/qualityControl/spotCheck',
              },
            ]
          },
          {
            name: 'program',
            path: '/program',
            icon: 'laptop',
            routes: [
              {
                path: '/program',
                redirect: '/program/list',
              },
              {
                name: 'list',
                path: '/program/list',
                icon: 'FileSearchOutlined',
                component: './supplier/program/List',
              },
              {
                name: 'summary',
                path: '/program/summary',
                icon: 'FileSearchOutlined',
                component: './supplier/program/Summary',
              },
              {
                name: 'detail',
                path: '/program/detail',
                icon: 'FileSearchOutlined',
                component: './supplier/program/Detail',
                hideInMenu:true,
              },
              {
                name: 'report',
                path: '/program/report',
                component: './supplier/program/Form',
                hideInMenu :true
              },
            ]
          },
      {
        name: 'process',
        path: '/process',
        icon: 'laptop',
        routes: [
          {
            path: '/process',
            redirect: '/process/list',
          },
          {
            name: 'list',
            path: '/process/list',
            icon: 'FileSearchOutlined',
            component: './supplier/process/List',
          },
          {
            name: 'report',
            path: '/process/report',
            component: './supplier/process/Form',
            hideInMenu :true
          },
        ]
      },
          {
            name: 'prod-order',
            path: '/prod-order',
            icon: 'cluster',
            hideChildrenInMenu:true,
            routes: [
              {
                path: '/prod-order',
                redirect: '/prod-order/list',
              },
              {
                name: 'list',
                path: '/prod-order/list',
                icon: 'FileSearchOutlined',
                component: './supplier/prodOrder/List',
              },
              {
                name: 'detail',
                path: '/prod-order/detail',
                icon: 'FileSearchOutlined',
                component: './supplier/prodOrder/Detail',
              },
            ]
          },
          // {
          //   name: 'reported-work',
          //   path: '/reported-work',
          //   icon: 'audit',
          //   routes: [
          //     {
          //       name: 'number-list',
          //       path:  '/reported-work/number-list',
          //       component: './supplier/report/NumberList',
          //     },
          //     {
          //       name: 'quality-list',
          //       path:  '/reported-work/quality-list',
          //       component: './supplier/report/QualityList',
          //     },
          //   ]
          // },
          {
            name: 'deliver',
            path: '/deliver',
            icon: 'shopping-cart',
            // hideChildrenInMenu:true,
            routes: [
              {
                path: '/deliver',
                redirect: '/deliver/list',
              },
              {
                name: 'can-deliver-list',
                path: '/deliver/can-deliver-list',
                icon: 'shopping-cart',
                component: './supplier/deliver/CanDeliverList',
              },
              {
                name: 'list',
                path: '/deliver/list',
                icon: 'shopping-cart',
                component: './supplier/deliver',
              },
              {
                name: 'detail',
                path: '/deliver/detail',
                icon: 'FileSearchOutlined',
                component: './supplier/deliver/detail',
                hideInMenu:true
              },
              {
                name: 'form',
                path: '/deliver/form',
                component: './supplier/deliver/Form',
                hideInMenu: true,
                showBack:true,
              },
            ]
          },

      {
        name: 'order-track',
        icon: 'select',
        path: '/order-track',
        hideChildrenInMenu:true,
        routes: [
          {
            path: '/order-track',
            redirect: '/order-track/list',
          },
          {
            name: 'list',
            path: '/order-track/list',
            icon: 'select',
            component: './supplier/orderTrack/list',
          },
        ]
      },

          {
            name: 'supplement',
            path: '/supplement',
            icon: 'car',
            // hideChildrenInMenu:true,
            routes: [
              {
                path: '/supplement',
                redirect: '/supplement/CanSupplementList/list',
              },
              {
                name: 'list',
                path: '/supplement/CanSupplementList/list',
                icon: 'FileSearchOutlined',
                component: './supplier/supplement/CanSupplementList',
              },
              {
                name: 'CanSupplement-patchDetails',
                path: '/supplement/CanSupplementList/PatchDetails',
                icon: 'FileSearchOutlined',
                component: './supplier/supplement/CanSupplementList/PatchDetails',
                hideInMenu:true,
              },
              {
                name: 'ApplySupplementList',
                path: '/supplement/ApplySupplementList/list',
                icon: 'FileSearchOutlined',
                component: './supplier/supplement/ApplySupplementList',
              },
              {
                name: 'SupplementedList',
                path: '/supplement/SupplementedList/list',
                icon: 'FileSearchOutlined',
                component: './supplier/supplement/SupplementedList',
              },
              {
                name: 'RejectSupplementList',
                path: '/supplement/RejectSupplementList/list',
                icon: 'FileSearchOutlined',
                component: './supplier/supplement/RejectSupplementList',
              },
            ]
          },
          {
            name: 'postSale',
            icon: 'BlockOutlined',
            path: '/postSale',
            component: './supplier/postSale',
          },
          {
            name: 'postSaleDetail',
            path: '/postSale/detail',
            component: './supplier/postSale/Detail',
            showBack:true,
            hideInMenu:true,
          },
          {
            name: 'ClaimList',
            icon: 'FileProtectOutlined',
            path: '/ClaimList',
            component: './supplier/ClaimList',
          },
          {
            name: 'postSale_ClaimDetail',
            path: '/postSale/postSale_ClaimDetail',
            component: './supplier/postSale/components/ClaimDetail',
            showBack:true,
            hideInMenu:true,
          },
          {
            name: 'badReportList',
            icon: 'BugOutlined',
            path: '/badReportList',
            component: './supplier/postSale/adverseReportList',
          },
          {
            name: 'machineSchedule',
            icon: 'apartment',
            path: '/machineSchedule',
            component: './supplier/machineSchedule',
          },
/*          {
            name: 'product-library',
            path: '/product-library',
            icon: 'apartment',
            component: './supplier/productLibrary/List',
          },*/
          {
            name: 'product-library',
            path: '/product-library',
            icon: 'apartment',
            // component: './supplier/productLibrary/List',
            routes: [
              {
                name: "productLibrary",
                path: '/product-library/list',
                component: './supplier/productLibrary/List',
              },
              {
                name: 'productStockInOutReport',
                path: '/product-library/productStockInOutReport',
                component: './supplier/productLibrary/productStockInOutReport',
              },
              {
                name: 'productStockInSupplier',
                path: '/product-library/productStockInSupplier',
                component: './supplier/productLibrary/productStockInSupplier',
              },
              {
                name: 'productStockOutSupplier',
                path: '/product-library/productStockOutSupplier',
                component: './supplier/productLibrary/productStockOutSupplier',
              },
              {
                name: 'productStorehouse-in-detail',
                icon: 'car',
                path: '/product-library/productStorehouse-in-detail',
                hideInMenu: true,
                component: './supplier/productLibrary/productStockInSupplier/detail',
                showBack:true,
              },
              {//
                name: 'productStorehouse-out-detail',
                icon: 'car',
                path: '/product-library/productStorehouse-out-detail',
                hideInMenu: true,
                component: './supplier/productLibrary/productStockOutSupplier/detail',
                showBack:true,
              },
            ]
          },
          {
            name: 'material-library',
            path: '/material-library',
            icon: 'cluster',
            routes:[
              {
                name: 'material',
                path: '/material-library/list',
                component: './supplier/materialLibrary/MaterialLibrary',
              },
              {
                name: 'surplus-material',
                path: '/material-library/surplus-material',
                component: './supplier/materialLibrary/SurplusMaterialLibrary',
              },
              {//废钢列表
                name: 'steelScrap-material',
                path: '/material-library/steelScrap-material',
                component: './supplier/materialLibrary/steelScrapMaterial/List',
              },
              {
                name: 'materialPurchase',
                path: '/material-library/materialPurchase',
                component: './supplier/materialLibrary/materialPurchase',
              },
              {//余料采购
                name: 'surplusPurchase',
                path: '/material-library/surplusPurchase',
                component: './supplier/materialLibrary/surplusPurchase',
              },
              {//材料调拨
                name: 'materialScience',
                path: '/material-library/materialScience',
                component: './supplier/materialLibrary/materialScience',
              },
              {//材料出入库查询报表
                name: 'materialStockInOutReport',
                path: '/material-library/materialStockInOutReport',
                component: './supplier/materialLibrary/materialStockInOutReport',
              }
            ]
          },
      {
        name: 'settlement',
        path: '/settlement',
        icon: 'MoneyCollect',
        // hideChildrenInMenu:true,
        routes:[
          {
            path: '/settlement',
            redirect: '/settlement/list',
          },
          {
            name: 'list',
            path: '/settlement/list',
            component: './supplier/settlement',
          },
          // {
          //   path: '/settlement',
          //   redirect: '/settlement/settlementPaymentPlan',
          // },
          {
            path: '/settlement/settlement_mtSale/mtSaleDetail',
            name: 'settlement_mtSaleDetail',
            component: './supplier/settlement/settlement_mtSale/MtSaleDetailed',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDeliverDetail',
            name: 'settlement_mtSaleDeliverDetail',
            component: './supplier/settlement/settlement_mtSale/MtSaleDetailed/MtOrderDeliverDetailed',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlementPaymentPlan',
            name: 'settlementPaymentPlan',
            component: './supplier/settlement/paymentPlan',
          },
          {
            path: '/settlement/settlementCenter',
            name: 'settlementCenter',
            component: './supplier/settlement/settlementCenter',
          },
          {
            path: '/settlement/settlementCenter/settlementDetail',
            name: 'settlementDetail',
            component: './supplier/settlement/settlementCenter/settlementDetail',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlementCenter/paymentApplyDetail',
            name: 'paymentApplyDetail',
            component: './supplier/settlement/settlementCenter/paymentApplyDetail',
            hideInMenu: true,
            showBack:true,
          },

          {
            path: '/settlement/settlementInvoice',
            name: 'settlementInvoice',
            component: './supplier/settlement/invoice',
          },
          {
            path: '/settlement/settlement_mtSale',
            name: 'settlement_mtSale',
            component: './supplier/settlement/settlement_mtSale',
          },
          // {
          //   path: '/settlement/claimList',
          //   name: 'claimList',
          //   component: './supplier/ClaimList',
          // },
          // {
          //   path: '/settlement/prodList',
          //   name: 'prodList',
          //   component: './supplier/prodOrder/List',
          // },
        ]
      },
          {
            name: 'invoice',
            icon: 'DollarOutlined',
            path: '/invoice',
            component: './supplier/InvoiceInformation/List',
          },
      {
        //我的工厂
        name: 'my-salesOrder',
        path: '/my-salesOrder',
        icon: 'cluster',
        routes:[
          {//销售管理
            name: 'my-factory',
            path: '/my-salesOrder/my-factory',
            icon: 'cluster',
            routes:[
              {//业务导航
                name:'businessNavigator',
                path:'/my-salesOrder/my-factory/businessNavigator',
                component:'./supplier/mySalesOrder/myFactory/businessNavigator/list',
              },
              {//销售订单
                name: 'order',
                path: '/my-salesOrder/my-factory/order',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/my-factory/order',
                    redirect: '/my-salesOrder/my-factory/order/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/my-factory/order/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/order/list',
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/my-factory/order/save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/order/save',
                    showBack:true,
                  },
                  {
                    name: 'form',
                    path: '/my-salesOrder/my-factory/order/form',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/order/Form',
                    showBack:true,
                  },
                  {
                    name: 'materialForm',
                    path: '/my-salesOrder/my-factory/order/materialForm',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/order/MaterialForm',
                    showBack:true,
                  },
                  {
                    name: 'materialSave',
                    path: '/my-salesOrder/my-factory/order/list/materialSave',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/order/list/materialSave',
                    showBack:true,
                  },
                  {
                    name: 'PartsDetail',
                    path: '/my-salesOrder/my-factory/order/PartsDetail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/order/PartsDetail',
                    showBack:true,
                  },
                  {
                    name: 'MaterialDetail',
                    path: '/my-salesOrder/my-factory/order/MaterialDetail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/order/MaterialDetail',
                    showBack:true,
                  },

                ]
              },
              {
                name: 'orderReport',
                path: '/my-salesOrder/my-factory/orderReport',
                component: './supplier/mySalesOrder/myFactory/orderReport/list',
              },

              {//发货管理
                name: 'deliver',
                path: '/my-salesOrder/my-factory/deliver',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/my-factory/deliver',
                    redirect: '/my-salesOrder/my-factory/deliver/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/my-factory/deliver/list',
                    icon: 'shopping-cart',
                    component: './supplier/mySalesOrder/myFactory/deliver',
                  },
                  {
                    name: 'detail',
                    path: '/my-salesOrder/my-factory/deliver/detail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/deliver/detail',
                    showBack:true,
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/my-factory/deliver/save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/deliver/save',
                    showBack:true,
                  },
                  {
                    name: 'MaterialSave',
                    path: '/my-salesOrder/my-factory/deliver/MaterialSave',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/deliver/MaterialSave',
                    showBack:true,
                  },   {
                    name: 'materialDetail',
                    path: '/my-salesOrder/my-factory/deliver/materialDetail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/deliver/MaterialDetail',
                    showBack:true,
                  },{
                    name: 'partsEdit',
                    path: '/my-salesOrder/my-factory/deliver/partsEdit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/deliver/deliveryPartsEdit',
                    showBack:true,
                  }, {
                    name: 'materialEdit',
                    path: '/my-salesOrder/my-factory/deliver/materialEdit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/myFactory/deliver/deliveryMaterialEdit',
                    showBack:true,
                  },
                ]
              },
              {//收款单
                name: 'collection',
                path: '/my-salesOrder/my-factory/collection',
                component: './supplier/mySalesOrder/myFactory/collection/list',
              },
              {//销售运费
                name: 'sales-freight',
                // icon: 'DollarOutlined',
                path: '/my-salesOrder/my-factory/sales-freight',
                component: './supplier/mySalesOrder/myFactory/salesFreight/list',
              },
              //销售发票申请
              {
                name:'sales-invoice',
                path:'/my-salesOrder/my-factory/sales-invoice',
                // icon:'DollarOutlined',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/my-factory/sales-invoice',
                    redirect: '/my-salesOrder/my-factory/sales-invoice/list',
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/list',
                    component: './supplier/mySalesOrder/myFactory/salesInvoice/List',
                  },
                  {
                    name: 'material-add',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/material-add',
                    component: './supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/save',
                    showBack: true,
                  },
                  {
                    name: 'part-add',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/part-add',
                    component: './supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/save',
                    showBack: true,
                  },
                  {
                    name: 'part-edit',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/part-edit',
                    component: './supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/edit',
                    showBack: true,
                  },
                  {
                    name: 'material-edit',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/material-edit',
                    component: './supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/edit',
                    showBack: true,
                  },
                  {
                    name: 'part-detail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/part-detail',
                    component: './supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/Detail/index',
                    showBack: true,
                  },
                  {
                    name: 'material-detail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/material-detail',
                    component: './supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/Detail/index',
                    showBack: true,
                  },
                ]
              }
            ]

          },
          //工厂采购管理
          {
            name: 'material-purchase',
            path: '/my-salesOrder/material-purchase',
            icon: 'gold',
            routes: [
              {
                path: '/my-salesOrder/material-purchase',
                redirect: '/my-salesOrder/material-purchase/list',
              },
              //业务导航
              {
                name: 'businessNavigator',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/businessNavigator',
                component: './supplier/mySalesOrder/materialPurchase/businessNavigator/list',
              },
              //采购入库单
              {
                name: 'material-management',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/material-management',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/material-purchase/material-management',
                    redirect: '/my-salesOrder/material-purchase/material-management/list',
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/material-purchase/material-management/list',
                    component: './supplier/mySalesOrder/materialPurchase/materialManagement/List'
                  },
                  //材料采购入库单详情
                  {
                    name: 'materialPurchaseDetail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/material-purchase/material-management/materialPurchaseDetail',
                    component: './supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/Detail/Detail',
                    showBack: true
                  },
                  //材料采购入库新增
                  {
                    name: 'materialPurchaseSave',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/material-purchase/material-management/materialPurchaseSave',
                    component: './supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/Form/index',
                    showBack: true
                  },
                  //零件采购入库单新增
                  {
                    name: 'partPurchaseSave',
                    path: '/my-salesOrder/material-purchase/material-management/partPurchaseSave',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/Form/index',
                    showBack: true
                  },
                  //零件采购入库单详情
                  {
                    name: 'partPurchaseDetail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/material-purchase/material-management/partPurchaseDetail',
                    component: './supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/Detail/index',
                    showBack: true
                  },
                  //材料采购入库修改
                  {
                    name: 'materialPurchaseEdit',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/material-purchase/material-management/materialPurchaseEdit',
                    component: './supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/materialEdit/index',
                    showBack: true
                  },
                  //零件采购入库修改
                  {
                    name: 'partPurchaseEdit',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/material-purchase/material-management/partPurchaseEdit',
                    component: './supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/partEdit/index',
                    showBack: true
                  },
                  //
                  // /my-salesOrder/material-purchase/material-management/list
                ],
              },
              {//付款申请
                name: 'payment',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/payment',
                component: './supplier/mySalesOrder/materialPurchase/payment/list',
              },
              {//采购运费
                name: 'purchase-freight',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/purchase-freight',
                component: './supplier/mySalesOrder/materialPurchase/purchaseFreight/list',
              },
              {//采购发票
                name: 'purchase-invoice',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/purchase-invoice',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/material-purchase/purchase-invoice',
                    redirect: '/my-salesOrder/material-purchase/purchase-invoice/list',
                  },
                  {//材料采购发票
                    name: 'list',
                    path: '/my-salesOrder/material-purchase/purchase-invoice/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List',
                    // showBack: true,
                  },
                  {//材料采购发票详情
                    name: 'materialDetail',
                    path: '/my-salesOrder/material-purchase/purchase-invoice/materialDetail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/Detail',
                    showBack: true,
                  },
                  {//新增材料采购发票
                    name: 'save',
                    path: '/my-salesOrder/material-purchase/purchase-invoice/save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/save',
                    showBack: true,
                  },
                  {//修改材料采购发票
                    name: 'edit',
                    path: '/my-salesOrder/material-purchase/purchase-invoice/edit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/edit',
                    showBack: true,
                  },
                  {//新增零件采购发票
                    name: 'partSave',
                    path: '/my-salesOrder/material-purchase/purchase-invoice/partSave',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/save',
                    showBack: true,
                  },
                  {//零件采购发票详情
                    name: 'partDetail',
                    path: '/my-salesOrder/material-purchase/purchase-invoice/partDetail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/Detail',
                    showBack: true,
                  },
                  {//修改零件采购发票
                    name: 'partEdit',
                    path: '/my-salesOrder/material-purchase/purchase-invoice/partEdit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/edit',
                    showBack: true,
                  },

                  // /my-salesOrder/material-purchase/purchase-invoice/partSave
                ]
              },

              /*              {//零件采购发票详情
                              name: 'partSales-detail',
                              icon: 'DollarOutlined',
                              path: '/my-salesOrder/material-purchase/purchase-invoice/partPurchase-detail',
                              component: '../supplier/mySalesOrder/materialPurchase/purchaseInvoice/list',
                            },*/

              /*              {
                              name: 'partSalesDetail',
                              icon: 'UserOutlined',
                              path: '/my-salesOrder/finance/invoice-information/partPurchaseDetail',
                              component: './supplier/mySalesOrder/finance/InvoiceInformation/List/PartPurchaseInvoice/Detail'
                            },*/
            ]
          },
          //工厂采购管理
          /*          {
                      name: 'material-purchase',
                      icon: 'DollarOutlined',
                      path: '/my-salesOrder/material-purchase',
                      hideChildrenInMenu: true,
                      routes: [
                        {
                          path: '/my-salesOrder/material-purchase/list',
                          redirect: '/my-salesOrder/material-purchase/list',
                        },
                        {
                          name: 'list',
                          icon: 'UserOutlined',
                          path: '/my-salesOrder/material-purchase/list',
                          component: './supplier/mySalesOrder/materialPurchase/List'
                        },
                       /!* {
                          name: 'materialSalesDetail',
                          icon: 'UserOutlined',
                          path: '/my-salesOrder/material-purchase/materialSalesDetail',
                          component: './supplier/mySalesOrder/materialPurchase/MaterialSalesInvoice/Detail'
                        },*!/
                      ]
                    },*/
          //供应商生产订单
          {
            name:'suProdOrder',
            icon:'cluster',
            path: '/my-salesOrder/suProdOrder',
            routes: [
              {
                path: '/my-salesOrder/suProdOrder',
                redirect: '/my-salesOrder/suProdOrder/list',
              },
              { //业务导航
                name: 'businessNavigator',
                path: '/my-salesOrder/suProdOrder/businessNavigator',
                icon: 'FileSearchOutlined',
                component: './supplier/mySalesOrder/suProdOrder/businessNavigator/list',
              },
              {
                name: 'list',
                path: '/my-salesOrder/suProdOrder/list',
                icon: 'FileSearchOutlined',
                component: './supplier/mySalesOrder/suProdOrder/List',
              },
              {
                name: 'detail',
                path: '/my-salesOrder/suProdOrder/detail',
                icon: 'FileSearchOutlined',
                component: './supplier/mySalesOrder/suProdOrder/Detail',
                showBack:true,
                hideInMenu:true,
              },
              {
                name: 'save',
                path: '/my-salesOrder/suProdOrder/save',
                icon: 'FileSearchOutlined',
                component: './supplier/mySalesOrder/suProdOrder/save',
                showBack:true,
                hideInMenu:true,
              },
              {
                name: 'edit',
                path: '/my-salesOrder/suProdOrder/edit',
                icon: 'FileSearchOutlined',
                component: './supplier/mySalesOrder/suProdOrder/edit',
                showBack:true,
                hideInMenu:true,
              },
              {
                name: 'work',
                path: '/my-salesOrder/suProdOrder/work',
                icon: 'FileSearchOutlined',
                component: './supplier/mySalesOrder/suProdOrder/Work',
                showBack:true,
                hideInMenu:true,
              },
            ]
          },

          {//工厂库存管理
            name: 'stock-management',
            path: '/my-salesOrder/stock-management',
            icon: 'cluster',
            routes:[
              { //业务导航
                name: 'businessNavigator',
                path: '/my-salesOrder/stock-management/businessNavigator',
                component: './supplier/mySalesOrder/stockManagement/businessNavigator/list',
              },
              {//产品入库单
                name: 'productStockIn',
                path: '/my-salesOrder/stock-management/productStockIn',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/productStockIn',
                    redirect: '/my-salesOrder/stock-management/productStockIn/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-management/productStockIn/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockIn/List',
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/stock-management/productStockIn/save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockIn/Form/index',
                    showBack: true,
                  },
                  {
                    name: 'detail',
                    path: '/my-salesOrder/stock-management/productStockIn/detail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockIn/Detail',
                    showBack: true,
                  },
                  {//修改产品入库单
                    name: 'edit',
                    path: '/my-salesOrder/stock-management/productStockIn/edit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockIn/edit',
                    showBack: true,
                  },

                ]
              },
              {//产品出库单
                name: 'productStockOut',
                path: '/my-salesOrder/stock-management/productStockOut',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/productStockOut',
                    redirect: '/my-salesOrder/stock-management/productStockOut/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-management/productStockOut/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockOut/List',
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/stock-management/productStockOut/save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockOut/Form/index',
                    showBack: true,
                  },
                  {
                    name: 'detail',
                    path: '/my-salesOrder/stock-management/productStockOut/detail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockOut/Detail',
                    showBack: true,
                  },
                  {//修改产品出库单
                    name: 'proStockOutEdit',
                    path: '/my-salesOrder/stock-management/productStockOut/proStockOutEdit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/productStockOut/edit',
                    showBack: true,
                  },

                ]
              },
              {//材料入库单
                name: 'materialStockIn',
                path: '/my-salesOrder/stock-management/materialStockIn',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/materialStockIn',
                    redirect: '/my-salesOrder/stock-management/materialStockIn/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-management/materialStockIn/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialStockIn/List',
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/stock-management/materialStockIn/save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialStockIn/Form/index',
                    showBack: true
                  },
                  /* {
                     name: 'detail',
                     path: '/stock-management/materialStockIn/detail',
                     icon: 'FileSearchOutlined',
                     component: './supplier/stockManagement/materialStockIn/Detail',
                   },*/
                  {//修改材料入库单
                    name: 'matStockInEdit',
                    path: '/my-salesOrder/stock-management/materialStockIn/matStockInEdit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialStockIn/edit',
                    showBack: true,
                  },

                ]
              },
              {//材料出库单
                name: 'materialStockOut',
                path: '/my-salesOrder/stock-management/materialStockOut',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/materialStockOut',
                    redirect: '/my-salesOrder/stock-management/materialStockOut/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-management/materialStockOut/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialStockOut/List',
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/stock-management/materialStockOut/save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialStockOut/Form/index',
                    showBack: true
                  },
                  /* {
                     name: 'detail',
                     path: '/stock-management/materialStockOut/detail',
                     icon: 'FileSearchOutlined',
                     component: './supplier/stockManagement/materialStockOut/Detail',
                   },*/
                  {//修改材料出库单
                    name: 'matStockOutEdit',
                    path: '/my-salesOrder/stock-management/materialStockOut/matStockOutEdit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialStockOut/edit',
                    showBack: true,
                  },
                ]
              },
              //材料入库单明细
              {
                name: 'material-stockInItem',
                path: '/my-salesOrder/stock-management/material-stockInItem',
                icon: 'experiment',
                component: './supplier/mySalesOrder/stockManagement/materialStockIn/Detail/detail',
                hideInMenu: true,
                showBack: true,
              },

              //材料出库单明细
              {
                name: 'material-stockOutItem',
                path: '/my-salesOrder/stock-management/material-stockOutItem',
                icon: 'experiment',
                component: './supplier/mySalesOrder/stockManagement/materialStockOut/Detail/detail',
                hideInMenu: true,
                showBack: true,
              },
              {//材料调拨单
                name: 'materialAllocation',
                path: '/my-salesOrder/stock-management/materialAllocation',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/materialAllocation',
                    redirect: '/my-salesOrder/stock-management/materialAllocation/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-management/materialAllocation/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialAllocation/List',
                  },
                  {
                    name: 'material-save',
                    path: '/my-salesOrder/stock-management/materialAllocation/material-save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialAllocation/Form/index',
                    showBack: true
                  },
                  {
                    name: 'product-save',
                    path: '/my-salesOrder/stock-management/materialAllocation/product-save',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialAllocation/productForm/index',
                    showBack: true
                  },
                  {
                    name: 'detail',
                    path: '/my-salesOrder/stock-management/materialAllocation/detail',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialAllocation/Detail',
                    showBack: true
                  },
                  {
                    name: 'product-edit',
                    path: '/my-salesOrder/stock-management/materialAllocation/product-edit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialAllocation/productEdit/index',
                    showBack: true
                  },
                  {
                    name: 'material-edit',
                    path: '/my-salesOrder/stock-management/materialAllocation/material-edit',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockManagement/materialAllocation/materialEdit/index',
                    showBack: true
                  },
                ]
              },
            ]
          },

          //工厂库存报表
          {
            name: 'stock-report',
            path: '/my-salesOrder/stock-report',
            icon: 'cluster',
            routes:[
              { //业务导航
                name: 'businessNavigator',
                path: '/my-salesOrder/stock-report/businessNavigator',
                component: './supplier/mySalesOrder/stockReport/businessNavigator/List',
              },
              {//产品库存表
                name: 'productStock',
                path: '/my-salesOrder/stock-report/productStock',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/productStock',
                    redirect: '/my-salesOrder/stock-report/productStock/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/productStock/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockReport/productStock/List',
                  },
                  /* {
                     name: 'detail',
                     path: '/stock-report/productStock/detail',
                     icon: 'FileSearchOutlined',
                     component: './supplier/stockReport/productStock/Detail',
                   },*/
                ]
              },

              {//材料库存表
                name: 'materialStock',
                path: '/my-salesOrder/stock-report/materialStock',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/materialStock',
                    redirect: '/my-salesOrder/stock-report/materialStock/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/materialStock/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockReport/materialStock/List',
                  },
                  /* {
                     name: 'detail',
                     path: '/stock-report/productStockIn/detail',
                     icon: 'FileSearchOutlined',
                     component: './supplier/stockReport/productStockIn/Detail',
                   },*/
                ]
              },
              {//材料出入库明细表
                name: 'materialStockInOutItem',
                path: '/my-salesOrder/stock-report/materialStockInOutItem',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/materialStockInOutItem',
                    redirect: '/my-salesOrder/stock-report/materialStockInOutItem/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/materialStockInOutItem/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockReport/materialStockInOutItem/List',
                  },
                  /* {
                     name: 'detail',
                     path: '/stock-report/productStockIn/detail',
                     icon: 'FileSearchOutlined',
                     component: './supplier/stockReport/productStockIn/Detail',
                   },*/
                ]
              },
              {//产品出入库明细表
                name: 'productStockInOutItem',
                path: '/my-salesOrder/stock-report/productStockInOutItem',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/productStockInOutItem',
                    redirect: '/my-salesOrder/stock-report/productStockInOutItem/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/productStockInOutItem/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockReport/productStockInOutItem/List',
                  },
                  /* {
                     name: 'detail',
                     path: '/stock-report/productStockIn/detail',
                     icon: 'FileSearchOutlined',
                     component: './supplier/stockReport/productStockIn/Detail',
                   },*/
                ]
              },
              {//实时库存
                name: 'realTimeStock',
                path: '/my-salesOrder/stock-report/realTimeStock',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/realTimeStock',
                    redirect: '/my-salesOrder/stock-report/realTimeStock/list',
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/realTimeStock/list',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockReport/realTimeStock/List',
                  },
                  {
                    name: 'redRushScrap',
                    path: '/my-salesOrder/stock-report/realTimeStock/redRushScrap',
                    icon: 'FileSearchOutlined',
                    component: './supplier/mySalesOrder/stockReport/realTimeStock/RedRushScrap',
                    showBack:true,
                  },
                ]
              },
            ]
          },



          //工厂财务管理
          {
            name: 'finance',
            icon: 'DollarOutlined',
            path: '/my-salesOrder/finance',
            routes: [
              {
                path: '/my-salesOrder/finance',
                redirect: '/my-salesOrder/finance/list',
              },
              //业务导航
              {
                name: 'businessNavigator',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/businessNavigator',
                component: './supplier/mySalesOrder/finance/businessNavigator/list',
              },
              //运费单查询
              {
                name: 'list',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/list',
                component: './supplier/mySalesOrder/finance/List',
              },
              //首付款确认
              {
                name: 'customer-period',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/customer-period',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/finance/customer-period',
                    redirect: '/my-salesOrder/finance/customer-period/list',
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/customer-period/list',
                    component: './supplier/mySalesOrder/finance/CustomerPeriod/List'
                  },
                ]
              },
              //收付款报表
              {
                name: 'payment-report',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/payment-report',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/finance/payment-report',
                    redirect: '/my-salesOrder/finance/payment-report/list',
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/payment-report/list',
                    component: './supplier/mySalesOrder/finance/PaymentReport/List'
                  },
                ]
              },
              //发票确认
              {
                name: 'invoice-information',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/invoice-information',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/finance/invoice-information',
                    redirect: '/my-salesOrder/finance/invoice-information/list',
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/invoice-information/list',
                    component: './supplier/mySalesOrder/finance/InvoiceInformation/List'
                  },
                  {
                    name: 'materialSalesDetail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/invoice-information/materialSalesDetail',
                    component: './supplier/mySalesOrder/finance/InvoiceInformation/List/MaterialSalesInvoice/Detail'
                  },
                  {//后工序发票详情
                    name: 'workProcessDetail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/invoice-information/workProcessDetail',
                    component: './supplier/mySalesOrder/finance/InvoiceInformation/List/WorkProcessInvoice/Detail'
                  },
                  //零件销售详情
                  {
                    name: 'partSalesDetail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/invoice-information/partSalesDetail',
                    component: './supplier/mySalesOrder/finance/InvoiceInformation/List/PartSalesInvoice/Detail',
                    showBack: true
                  },
                  //零件采购详情
                  {
                    name: 'partSalesDetail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/invoice-information/partPurchaseDetail',
                    component: './supplier/mySalesOrder/finance/InvoiceInformation/List/PartPurchaseInvoice/Detail',
                    showBack: true
                  },
                  //材料采购详情
                  {
                    name: 'partSalesDetail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/invoice-information/materialPurchaseDetail',
                    component: './supplier/mySalesOrder/finance/InvoiceInformation/List/MaterialPurchaseInvoice/Detail',
                    showBack: true
                  },
                ]
              },
            ]
          },
          //工厂基础档案
          {
            name: 'basic-archives',
            path: '/my-salesOrder/basic-archives',
            icon: 'cluster',
            routes:[
              {//业务导航
                name: 'businessNavigator',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/businessNavigator',
                component: './supplier/mySalesOrder/basicArchives/businessNavigator/list',
              },

              {//工厂零件档案
                name: 'part-file',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/part-file',
                component: './supplier/mySalesOrder/basicArchives/partFile',
              },
              {
                name: 'BatchImportPartsForm',
                path: '/my-salesOrder/basic-archives/part-file/BatchImportPartsForm',
                icon:'solution',
                component: './supplier/mySalesOrder/basicArchives/partFile/BatchImportPartsForm',
                hideInMenu: true,
                showBack:true,
              },
              {//工厂客户档案
                name: 'customer-profile',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/customer-profile',
                component: './supplier/mySalesOrder/basicArchives/customerProfile',
              },
              {
                name: 'customer-profile',
                path: '/my-salesOrder/basic-archives/customer-profile/form',
                icon: 'car',
                component: './supplier/mySalesOrder/basicArchives/customerProfile/form',
                hideInMenu: true,
                showBack: true,
              },
              {   //详情或修改
                name: 'detail',
                icon: 'team',
                path: '/my-salesOrder/basic-archives/customer-profile/detail',
                component: './supplier/mySalesOrder/basicArchives/customerProfile/CustomerDetail',
                hideInMenu: true,
                showBack: true
              },


              {//工厂供应商档案
                name: 'supplier-file',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/supplier-file',
                component: './supplier/mySalesOrder/basicArchives/supplierFile',
              },
              {//工厂仓库档案
                name: 'warehouse archives',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/warehouse-archives',
                component: './supplier/mySalesOrder/basicArchives/warehouseArchives',
              },
              {//工厂废钢单价档案
                name: 'scrapPriceArchives',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/scrapPrice-archives',
                component: './supplier/mySalesOrder/basicArchives/scrapPriceArchives',
              },
              {//工厂收发类别
                name: 'sendReceive-category',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/sendReceive-category',
                component: './supplier/mySalesOrder/basicArchives/sendReceiveCategory',
              },
              {//工厂操作系统日志
                name: 'operating-log',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/operating-log',
                component: './supplier/mySalesOrder/basicArchives/oparatingLog',
              },
            ]
          },

        ]
      },
          {
            name: 'setting',
            path: '/setting',
            icon: 'setting',
            routes:[
              {
                name: 'profile',
                path: '/setting/profile',
                icon: 'UserOutlined',
                component: './supplier/profile',
              },
              {
                name: 'subAccount',
                path: '/setting/supSubAccount',
                icon: 'TeamOutlined',
                component: './supplier/setting/supSubAccount',
              },
              {
                name: 'supplierRole',
                path: '/setting/roleManagement',
                icon: 'TeamOutlined',
                component: './supplier/setting/roleManagement',
              },
              {
                name: 'roleMember',
                path: '/setting/roleManagement/roleMember',
                hideInMenu: true,
                showBack: true,
                component: './supplier/setting/roleManagement/Form/RoleMemberForm',
              },
              {
                name: 'unit-information',
                path: '/setting/unit-information',
                icon: 'audit',
                component: './supplier/setting/unitInformation',
              },
              {
                name: 'factory-management',
                path: '/setting/factory-management',
                icon: 'audit',
                component: './supplier/setting/factoryManagement',
              },
              {
                name: 'cutting-method',
                path: '/setting/cutting-method',
                icon: 'block',
                component: './supplier/setting/cuttingMethod',
              },
              {
                name: 'cutting-unit-price',
                path: '/setting/cutting-unit-price',
                icon: 'dollar',
                component: './supplier/cuttingUnitPrice/CuttingUnitPrice',
              },
              {
                name: 'device-information',
                path: '/setting/device-information',
                icon: 'reconciliation',
                component: './supplier/setting/deviceInformation',
              },
              {
                name: 'payment-method',
                path: '/setting/payment-method',
                icon: 'money-collect',
                component: './supplier/setting/paymentMethod',
              },
              {
                name: 'stock-manager',
                path: '/setting/stock-manager',
                icon: 'money-collect',
                component: './supplier/setting/stockManager',
              },
              {
                name: 'invoice-manager',
                path: '/setting/invoice-manager',
                icon: 'money-collect',
                component: './supplier/setting/Invoice',
              },
              {
                name: 'receiveAddress-manager',
                path: '/setting/receiveAddress-manager',
                icon: 'money-collect',
                component: './supplier/setting/ReceiveAddress',
              },
            ]
          }
    ],
  },
]
