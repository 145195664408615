import {Effect} from 'dva';

import {download, upload} from '@/services/common/document';

export interface DocumentStateType {
}

export interface DocumentModelType {
  namespace: string;
  state: DocumentStateType;
  effects: {
    upload: Effect;
    multipleUpload: Effect;
    download: Effect;
  };
  reducers: {
  };
}

const Model: DocumentModelType = {
  namespace: 'document',

  state: {
  },

  effects: {
    *upload({ payload }, { call }) {
      const {success,id,objID} = yield call(upload, payload);
      return {success,id,objID};
    },
    *multipleUpload({ payload }, { call }) {
      let data=[];
      let flag=true;
      for(let i=0;i<payload.length;i++){
        const {success,id,objID} = yield call(upload, payload[i]);
        if(!success){
          flag=false;
        }
        data.push({status:success?'done':'error',id,objID})
      }
      return {success:flag,data};
    },
    *download({ payload }, { call }) {
      return yield call(download, payload);
    },
  },

  reducers: {

  },
};

export default Model;
