import {parse, ParsedUrlQuery} from 'querystring';
import pathRegexp from 'path-to-regexp';
import { Route } from '@/models/connect';
import {router} from "umi";
import {stringify} from "qs";
import moment from "moment";
import { message } from 'antd';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = <T extends Route>(
  router: T[] = [],
  pathname: string,
): T | undefined => {
  const authority = router.find(
    ({ routes, path = '/' }) =>
      (path && pathRegexp(path).exec(pathname)) ||
      (routes && getAuthorityFromRouter(routes, pathname)),
  );
  if (authority) return authority;
  return undefined;
};

export const getRouteAuthority = (path: string, routeData: Route[]) => {
  let authorities: string[] | string | undefined;
  routeData.forEach(route => {
    // match prefix
    if (pathRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      }
      // exact match
      if (route.path === path) {
        authorities = route.authority || authorities;
      }
      // get children authority recursively
      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};


export function isArray(array:any) {
  return Object.prototype.toString.call(array) === '[object Array]';
}

/**
 * 数组转树形
 * @param nodes
 * @param options
 * @returns {*}
 */
export function arrayToTree(nodes:any, options:any = {}) {
  let i, l,
    key = options.idKey || 'id',
    parentKey = options.pIdKey || 'parentId',
    childKey = options.children || 'children';
  if (!nodes) return [];
  if (isArray(nodes)) {
    const r = [];
    const tmpMap = {};
    for (i = 0, l = nodes.length; i < l; i++) {
      tmpMap[nodes[i][key]] = nodes[i];
    }
    for (i = 0, l = nodes.length; i < l; i++) {
      if (tmpMap[nodes[i][parentKey]] && nodes[i][key] !== nodes[i][parentKey]) {
        if (!tmpMap[nodes[i][parentKey]][childKey])
          tmpMap[nodes[i][parentKey]][childKey] = [];
        tmpMap[nodes[i][parentKey]][childKey].push(nodes[i]);
      } else {
        r.push(nodes[i]);
      }
    }
    return r;
  } else {
    return [nodes];
  }
}

export function imgToBase64(img:any, callback:any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getSuffix(fileName:string):string{
  const index = fileName.lastIndexOf(".");
  const suffix = fileName.substr(index);
  return suffix;
}

export function isLtM(size:number,lt:number):boolean{
  return  size / 1024 / 1024 < lt;
}

export function isDxfOrDwg(file?: any){
  if(!file){
    // message.warn('请上传dxf或dwg文件');
    return false;
  }
  const suffix = getSuffix(file.name).toLocaleLowerCase();
  if (!drewSheetAccepts.includes(suffix)) {
    // message.warn(getDxfMessage(file));
    return false
  }
  // const isLt10M = isLtM(file.size,10);
  // if (!isLt10M) {
  //   message.warn('文件大小不能超过 10MB!');
  //   return false
  // }
  return true;
}

export function checkFileName(file?: any){
  var filename = file.name;
  let regex = /[\s\?∅]/;
  let  result = regex.test(filename);
  if (result){
    //  console.warn("文件名称含有空格和'?'");
    message.warn("文件名称含有空格或'?/∅'，请修改文件名称，重新上传！");
    return false;
  }else {
    return true;
  }
}

export const drewSheetAccepts=['.dwg','.dxf'];

export function getImageUrl(flag?:boolean){
  if(flag){
    return `/api/module-operation!executeOperation?moduleId=CreateImageCertCode&operation=FileCertImage&temp=${new Date().getTime()}`;
  }else{
    return `/api/module-operation!executeOperation?moduleId=CreateImageCertCode&operation=FileCertImage`;
  }
}

export function getDownloadUrl(fileId?:string){
  const token = encodeURIComponent('{"data":{"isMulti":false,"dataId":"' + fileId + '","isShow":0}}');
  return `/api/module-operation!executeOperation?operation=FileDown&token=${token}`;
}

export function getDownloadUrls(fileIds?:string[]){// JSON.parse(fileIds)
  // @ts-ignore
  let fileId = fileIds.map(item=> JSON.stringify(item))
  const token =encodeURIComponent('{"data":{"isMulti":true,"dataId":[' + fileId + '],"isShow":0}}');
  return `/api/module-operation!executeOperation?operation=FileDown&token=${token}`;
}



export function getUploadUrl(fileId?:string){
  const token=encodeURIComponent('{"data":{"dataId":"' + fileId + '","uploadId":"","storeType":"1"}}');
  return `/api/module-operation!executeOperation?operation=FileUpload&token=${token}`;
}

export function getBase64(img: File|Blob, callback: (arg0: string | ArrayBuffer | null) => any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

/**
 * 解析url参数
 * @param searchParams
 * @returns {*}
 */
export function searchParamsParse(searchParams?:any):ParsedUrlQuery {
  if (searchParams && searchParams[0] === '?') {
    return parse(searchParams.substring(1))
  } else {
    return parse(searchParams);
  }
}

export  function  formatSeconds(value?:any) {
  let theTime = parseInt(value);// 秒
  let theTime1 = 0;// 分
  let theTime2 = 0;// 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  let result =  parseInt(theTime) + "秒";
  if (theTime1 > 0) {
    result = parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result =  parseInt(theTime2) + "小时" + result;
  }
  return result;
}

export function getFirstDay(value?:any){
  const date = new Date(value);
  let y = date.getFullYear(); //获取年份
  let m = date.getMonth() + 1; //获取月份
  let d = '01'
  m = m < 10 ? '0' + m : m; //月份补 0
  return [y,m,d].join("-")
}

export function getLastDay(value?:any){
  const date = new Date(value);
  var y = date.getFullYear(); //获取年份
  var m = date.getMonth() + 1; //获取月份
  var d = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); //获取当月最后一日
  m = m < 10 ? '0' + m : m; //月份补 0
  d = d < 10 ? '0' + d : d; //日数补 0
  return [y,m,d].join("-")
}


export function setCard(userInfo:any) {
  let can = document.createElement('canvas');
  can.width = document.documentElement.clientWidth - 10000
  can.height = 140;

  let cans = can.getContext('2d');
  cans.beginPath();
  cans.rotate(-10 * Math.PI / 180);
  cans.font = '18px Arial';
  cans.fillStyle = '#ff0000';
  cans.setFillStyle = "#b9b9b9";
  cans.fillText(userInfo.Name, 50, 50);
  cans.fillText(userInfo.WWID, 50, 80);
  cans.fillText("内部使用，不得外传", 50, 110);
  cans.fill();
  // cans.closePath();
  let span = document.createElement('span');
  span.style.pointerEvents = 'none';
  span.style.top = '30px';
  span.style.left = '0px';
  span.style.position = 'fixed';
  span.style.zIndex = '499';
  span.style.textAlign = 'center';
  span.style.width = document.documentElement.clientWidth - 100 + 'px';
  span.style.height = document.documentElement.clientHeight - 100 + 'px';
  span.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
  span.style.opacity = '.5';
  document.images.namedItem(span)
}


export  function numberToUpper(value?:any) {
  var cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"); //汉字的数字
  var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
  var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
  var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
  var cnInteger = "整"; //整数金额时后面跟的字符
  var cnIntLast = "元"; //整型完以后的单位
  var maxNum = 999999999999999.9999; //最大处理的数字
  var IntegerNum; //金额整数部分
  var DecimalNum; //金额小数部分
  var ChineseStr = ""; //输出的中文金额字符串
  var parts; //分离金额后用的数组，预定义
  if (value == "") {
    return "";
  }
  value = parseFloat(value);
  if (value >= maxNum) {
    alert('超出最大处理数字');
    return "";
  }
  if (value == 0) {
    ChineseStr = cnNums[0] + cnIntLast + cnInteger;
    return ChineseStr;
  }
  value = value.toString(); //转换为字符串
  if (value.indexOf(".") == -1) {
    IntegerNum = value;
    DecimalNum = '';
  } else {
    parts = value.split(".");
    IntegerNum = parts[0];
    DecimalNum = parts[1].substr(0, 4);
  }
  if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
    var zeroCount = 0;
    var IntLen = IntegerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = IntegerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          ChineseStr += cnNums[0];
        }
        zeroCount = 0; //归零
        ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        ChineseStr += cnIntUnits[q];
      }
    }
    ChineseStr += cnIntLast;
    //整型部分处理完毕
  }
  if (DecimalNum != '') { //小数部分
    var decLen = DecimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = DecimalNum.substr(i, 1);
      if (n != '0') {
        ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (ChineseStr == '') {
    ChineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (DecimalNum == '') {
    ChineseStr += cnInteger;
  }
  return ChineseStr;
}

export interface PaginationParams{
  current?:number |1;
  pageSize?:number |10;
}

export interface PaginationProps extends PaginationParams{
  total:number,
}

export const defaultPagination:PaginationProps={
  total:0,
  current:1,
  pageSize:10,
};

export interface PageInfo<T> {
  items:T [];
  pagination:PaginationProps;
}
export interface PageInfos<T> {
  items:T [];
  pagination:PaginationProps;
  success:boolean;
}

export function replaceUrl(location:any,params:any){
  delete params.total;
  router.replace({
    pathname:location.pathname,
    search:stringify({
      ...params,
      current:params.current,
      pageSize:params.pageSize,
    })
  })
}

export function htmlEscape(text:any){
  if(text){
    return text.replaceAll('&&','&amp;&amp;');
  }
  return text;
}
//数组对象去重
export function unique(arr:any=[]) {
  let map = new Map();
  for (let item of arr) {
    map.set(item.id, item);
  }
  return [...map.values()];
}
export function textEscape(text:any){
  if(text){
    return text.replaceAll('&amp;', '&')
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>');
  }
  return text
}


export function functionToJson(object:any){
  return JSON.stringify(object, function(key, val) {
    if (typeof val === 'function') {
      return val + '';
    }
    return val;
  });
}

export function jsonToFunction(object:any){
  return JSON.parse(object,function(k,v){
    if(v.indexOf && v.indexOf('function') > -1){
      return eval("(function(){return "+v+" })()")
    }
    return v;
  });
}

export function placeholderParse(type:string,value:any){
  if(value===null||!value){
    return undefined;
  }
  switch (type) {
    case 'input':
    case 'inputNumber':
    case 'inputFloat':
    case 'checkbox':
    case 'day':
    case 'month':
    case 'year':
    case 'select':
    case 'radio':
    case 'selectMultiple':
    case 'factoryPicker':
      return value;
    case 'dayRange':
    case 'monthRange':
    case 'yearRange':
      return (jsonToFunction(value)||[]).map((item:any)=>{
        return item;
      });
    default:
      return value;
  }
}

export function defaultPropsParse(type:string,value:any){
  if(value===null||!value){
    return undefined;
  }
  switch (type) {
    case 'input':
    case 'select':
    case 'radio':
      return value;
    case 'inputNumber':
      return parseInt(value);
    case 'inputFloat':
      return parseFloat(value);
    case 'checkbox':
      return value.toString()==='true';
    case 'day':
      return moment(value,'YYYY-MM-DD');
    case 'month':
      return moment(value,'YYYY-MM');
    case 'year':
      return moment(value,'YYYY');
    case 'dayRange':
      return (jsonToFunction(value)||[]).map((item:any)=>{
        return moment(item,'YYYY-MM-DD');
      });
    case 'monthRange':
      return (jsonToFunction(value)||[]).map((item:any)=>{
        return moment(item,'YYYY-MM');
      })
    case 'yearRange':
      return (jsonToFunction(value)||[]).map((item:any)=>{
        return moment(item,'YYYY');
      })
    case 'selectMultiple':
    case 'factoryPicker':
      return (jsonToFunction(value)||[]);
    default:
      return value;
  }
}

export function fieldParse(type:string,value:any){
  switch (type) {
    case 'string':
      return value;
    case 'integer':
      return parseInt(value);
    case 'float':
      return parseFloat(value);
    case 'boolean':
      return value==='true';
    case 'array':
    case 'object':
    case 'json':
      return jsonToFunction(value);
    case 'day':
      return  moment(value).format('YYYY-MM-DD');
    case 'month':
      return  moment(value).format('YYYY-MM');
    case 'year':
      return moment(value).format('YYYY');
    default:
      return value;
  }
}

export function valueParse(type:string,value:string){
  switch (type) {
    case 'day':
    case 'dayRange':
      return moment(value,'YYYY-MM-DD');
    case 'month':
    case 'monthRange':
      return moment(value,'YYYY-MM');
    case 'year':
    case 'yearRange':
      return moment(value,'YYYY')
    default:
      return value;
  }
}

export function isMomentComponent(type:string){
  return type === 'day' ||
    type === 'month' ||
    type === 'year' ||
    type === 'dayRange' ||
    type === 'monthRange' ||
    type === 'yearRange';
}

// 去除空对象
export const isFalsy = (value:any) => (value === 0 ? false : !value)
export const isVoid = (value:any) => value === undefined || value === null || value === ''
export function cleanObject(object:any) {
  // Object.assign({}, object)
  if (!object) {
    return {}
  }
  const result = {
    ...object,
  }
  Object.keys(result).forEach((key) => {
    const value = result[key]
    if (isVoid(value)) {
      delete result[key]
    }
  })
  return result
}
// 深拷贝
export function ObjectCopy(parseForm:Object, originalForm:Object) {
  // eslint-disable-next-line no-return-assign
  Object.keys(parseForm).map((key) => (parseForm[key] = originalForm[key]))
}
  //下载文件
  export function download(link:any, name:any) {
    if(!name){
            name=link.slice(link.lastIndexOf('/') + 1)
    }
    let eleLink = document.createElement('a')
    eleLink.download = name
    eleLink.style.display = 'none'
    eleLink.href = link
    document.body.appendChild(eleLink)
    eleLink.click()
    document.body.removeChild(eleLink)
  }
  /**
 * 数字存储大小格式化
 * @param {number} num 存储大小 单位：Byte
 * @param {number} digits 保留几位小数，默认2
 * @return {string} 2MB
 */
   export function formatStorage(num:any, digits:any) {
  digits = digits || 2
  if (num < 1024) {
    return num + 'B'
  }
  num = (num * 1000) / 1024
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
        si[i].symbol +
        'B'
      )
    }
  }
}

export function toFixed(num:any,d:any) {//返回的是数值型
  num *=Math.pow(10,d);
  num = Math.round(num);
  return num/(Math.pow(10,d));
}
