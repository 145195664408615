import request from "@/utils/request";

export async function query(supplierId:string): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetFactory',{
    method:'POST',
    data:{
      supplierId:supplierId
    }
  });
}

export async function queryCentre(supplierId:string): Promise<any> {
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetFactory',{
    method:'POST',
    data:{
      supplierId:supplierId
    }
  });
}
