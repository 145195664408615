import {Effect} from "dva";
import {
  sendAuthCode ,
  isNeedImageCode,
  checkImageCode,
  sendCertCodeNew,
  checkAuthCode,
} from "@/services/common/authCode";

export interface AuthCodeModelType {
  namespace: 'authCode';
  state: {

  };
  effects: {
    sendAuthCode:Effect;
    isNeedImageCode:Effect;
    checkImageCode:Effect;
    checkAuthCode:Effect;
    sendCertCodeNew:Effect;
  };
  reducers: {

  };
}

const AuthCodeModel: AuthCodeModelType = {
  namespace: 'authCode',
  state:{
  },
  effects:{
    *sendAuthCode({payload}, { call }) {
      try{
        const {success,data} = yield call(sendAuthCode, payload);
        return {success,data};
      } catch (e) {
        return {success:false,data:null};
      }
    },
    *checkAuthCode({payload}, { call }) {
      try {
        const {success,data}=yield call(checkAuthCode,payload);
        return {success,data}
      }catch (e) {
        return {success:false,data:null}
      }
    },
    *isNeedImageCode({payload}, { call }) {
      try{
        const {data} = yield call(isNeedImageCode, payload);
        return data.isNeedImageCertCode;
      } catch (e) {
        return true;
      }
    },
    *sendCertCodeNew({payload}, { call }) {
      try{
        const {success,data} = yield call(sendCertCodeNew, payload);
        return {success,data}
      } catch (e) {
        return {success:false,data:null}
      }
    },
    *checkImageCode({payload}, { call }) {
      try{
        const {data} = yield call(checkImageCode, payload);
        return data.isCheckOK;
      } catch (e) {
        return false;
      }
    },
  },
  reducers: {

  }
};
export default AuthCodeModel;
