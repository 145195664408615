import request from "@/utils/request";

export async function sendAuthCode(params:any) {
  return request('/common_certcode_service/ServerFunc_SendCertCode', {
    method: 'POST',
    data: {
      phone:params.phone,
      imageCertCode:params.imageCertCode,
    },
  });
}

export async function checkAuthCode(payload:any): Promise<any> {//校验验证码
  return request("/common_certcode_service/ServerFunc_CheckCertCode",{
    method: 'POST',
    data: {
      certCode:payload.certCode,
    }
  });
}

export async function isNeedImageCode(params:any) {
  return request('/common_certcode_service/ServerFunc_IsNeedImageCertCode', {
    method: 'POST',
  });
}

//图形验证码
export async function sendCertCodeNew(params:any) {
  return request('/common_certcode_service/ServerFunc_SendCertCodeNew', {
    method: 'POST',
    data: {
      imageCertCode:params.imageCertCode,
    },
  });
}

export async function checkImageCode(params:{certCode:string}) {
  return request('/common_certcode_service/ServerFunc_CheckImageCertCode', {
    method: 'POST',
    data: params,
  });
}
