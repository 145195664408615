import request from '@/utils/request';

export async function queryProvince(): Promise<any> {//获取区域信息-省
  return request('/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_Province',{
    method:'POST',
  });
}

export async function queryProvinces(): Promise<any> {//获取区域信息-所有省份
  return request('/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_Provinces',{
    method:'POST',
  });
}

export async function queryCity(id:string): Promise<any> {//获取区域信息-市
  return request('/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_City',{
    method:'POST',
    data:{bizId:id}
  });
}

export async function queryDistric(id:string): Promise<any> {//获取区域信息-区
  return request('/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_Distric',{
    method:'POST',
    data:{bizId:id}
  });
}

export async function queryLocationByIP(id:string): Promise<any> {//根据IP获取位置
  return request('/yuncut_customer_db/ServerFunc_GetDefaultAddr',{
    method:'POST',
  });
}

