export default {
  'app.settings.menuMap.basic': '基本設定',
  'app.settings.menuMap.security': 'セキュリティ設定',
  'app.settings.menuMap.binding': 'アカウントのバインディング',
  'app.settings.menuMap.notification': '新しいメッセージ通知',
  'app.settings.basic.avatar': 'アバター',
  'app.settings.basic.change-avatar': 'アバターを変更',
  'app.settings.basic.email-message': 'メールアドレスを入力してください!',
  'app.settings.basic.nickname': 'ニックネーム',
  'app.settings.basic.nickname-message': 'ニックネームを入力してください!',
  'app.settings.basic.profile': '個人プロフィール',
  'app.settings.basic.profile-message': '個人プロフィールを入力してください!',
  'app.settings.basic.profile-placeholder': '個人プロフィール',
  'app.settings.basic.country': '国/地域',
  'app.settings.basic.country-message': '国または地域を入力してください!',
  'app.settings.basic.geographic': '所在地',
  'app.settings.basic.geographic-message': '所在地を入力してください!',
  'app.settings.basic.address': '住所',
  'app.settings.basic.address-message': '住所を入力してください!',
  'app.settings.basic.phone-message': '電話番号を入力してください!',

  'app.settings.basic.phone': '連絡先番号',
  'app.settings.basic.update': '更新基本信息',

  'app.settings.basic.tab-login-credentials': 'パスワードでログイン',
  'app.settings.basic.tab-login-phone': '携帯電話でログイン',
  'app.settings.basic.login-phone': '携帯電話番号',
  'app.settings.basic.login-error': 'アカウントまたは認証コードが間違っています',
  'app.settings.basic.login1-error': 'アカウントまたはパスワードが間違っています',
  'app.settings.basic.input-login-accountOrPhone': 'ユーザー名/携帯電話番号を入力してください',
  'app.settings.basic.input-login-phone': '携帯電話番号を入力してください',
  'app.settings.basic.login-phone-error': '携帯電話番号の形式が間違っています',
  'app.settings.basic.login-phone-validator': 'この携帯電話番号は登録されていません。まずアカウントを登録してください',
  'app.settings.basic.login-password': 'パスワード',
  'app.settings.basic.login-phone-code': '認証コード',
  'app.settings.basic.login-phone-get-code': '認証コードを取得',
  'app.settings.basic.login-phone-get-code-time': '秒',
  'app.settings.basic.forgot-password': 'パスワードを忘れましたか？',
  'app.settings.basic.registered-account': 'アカウントを登録',
  'app.settings.basic.other-account': '他のアカウントでログイン',

  'app.settings.basic.login': 'ログイン',
  'app.settings.basic.freeRegistration': '無料登録',
  'app.settings.basic.email': '郵便',

  'app.settings.security.strong': '強い',
  'app.settings.security.medium': '中',
  'app.settings.security.weak': '弱い',
  'app.settings.security.password': 'アカウントのパスワード',
  'app.settings.security.password-description': '現在のパスワードの強度',
  'app.settings.security.phone': 'セキュリティ電話',
  'app.settings.security.phone-description': 'バインドされた電話',
  'app.settings.security.question': 'セキュリティ質問',
  'app.settings.security.question-description': 'セキュリティ質問は設定されていませんが、アカウントの安全を効果的に保護できます',
  'app.settings.security.email': 'バックアップメール',
  'app.settings.security.email-description': 'バインドされたメール',
  'app.settings.security.mfa': 'MFAデバイス',
  'app.settings.security.mfa-description': 'MFAデバイスはバインドされていませんが、バインド後に二次確認が可能です',
  'app.settings.security.modify': '変更',
  'app.settings.security.set': '設定',
  'app.settings.security.bind': 'バインド',
  'app.settings.binding.taobao': 'タオバオをバインド',
  'app.settings.binding.taobao-description': '現在タオバオアカウントはバインドされていません',
  'app.settings.binding.alipay': 'アリペイをバインド',
  'app.settings.binding.alipay-description': '現在アリペイアカウントはバインドされていません',
  'app.settings.binding.dingding': 'ディンディンをバインド',
  'app.settings.binding.dingding-description': '現在ディンディンアカウントはバインドされていません',
  'app.settings.binding.bind': 'バインド',
  'app.settings.notification.password': 'アカウントのパスワード',
  'app.settings.notification.password-description': '他のユーザーからのメッセージは、サイト内メッセージとして通知されます',
  'app.settings.notification.messages': 'システムメッセージ',
  'app.settings.notification.messages-description': 'システムメッセージは、サイト内メッセージとして通知されます',
  'app.settings.notification.todo': 'やるべきこと',
  'app.settings.notification.todo-description': 'やるべきことは、サイト内メッセージとして通知されます',
  'app.settings.open': '開く',
  'app.settings.close': '閉じる',

  'app.settings.basic.exclusiveManager': '専任マネージャー',
  'app.settings.basic.advisoryService': 'アドバイザリーサービス',
  'app.settings.basic.name': '名前',
  'app.settings.basic.addWechat': 'WeChatを追加して便利なコミュニケーションを実現',
  'app.settings.basic.noWechat': 'WeChatはまだありません',
  'app.settings.basic.fullScreen': '全画面表示',
  'app.settings.basic.inquireNow': '問い合わせ',
  'app.settings.basic.headerTitle': '・鋼板切断共用プラットフォーム',
  'app.settings.basic.headerTitle1': '鋼板切断業界唯一の革新的応用事例が工業情報化部の産業用選定',
  'app.settings.basic.headerTitle2': 'クイック見積り 高い稼働率',
  'app.settings.basic.identity': '登録する必要がある身分を選択してください',
  'app.settings.basic.partner': '私はパートナーです',
  'app.settings.basic.isCustomer': '私は顧客です',

  'app.settings.basic.customerRegistration': '顧客登録',
  'app.settings.basic.customerRegistrationTip': 'ユーザー名は2〜20文字の範囲で設定できます（漢字、英数字、アンダースコアを含む）',
  'app.settings.basic.customerName': 'ユーザー名:',
  'app.settings.basic.inputAccountName': 'ユーザー名を入力してください:',
  'app.settings.basic.passwordTip': 'パスワードは6〜20文字の範囲で設定できます（数字、アルファベット、アンダースコアを含む）',
  'app.settings.basic.passwordLabel': 'パスワード:',
  'app.settings.basic.passwordTip1': 'パスワードを入力してください',
  'app.settings.basic.passwordTip2': 'パスワードの確認:',
  'app.settings.basic.passwordTip3': 'パスワードを再度入力してください',
  'app.settings.basic.phoneLabel': '携帯番号:',
  'app.settings.basic.inputAccountPhone': '携帯番号を入力してください',
  'app.settings.basic.register-code': '認証コード:',
  'app.settings.basic.input-register-code': '認証コードを入力してください',
  'app.settings.basic.get-register-code': '認証コードを取得',
  'app.settings.basic.register-text': '私は読んで同意しました',
  'app.settings.basic.yuncut-user-clause': '《クラウドカットユーザー契約》',
  'app.settings.basic.user-privacy-clause': '《ユーザープライバシー条項》',
  'app.settings.basic.user-privacy-ok': '同意して登録する',
  'app.settings.basic.had-account': '既にアカウントをお持ちですか？',
  'app.settings.basic.now-login': '今すぐログイン',
  'app.settings.basic.register-other-login': '他のアカウントでログイン',

  'app.settings.basic.header_text1': 'ワンストップ鋼板切断共有プラットフォーム',
  'app.settings.basic.header_text31': '迅速な見積もり',
  'app.settings.basic.header_text32': '高い利用率',
  'app.settings.basic.header_text4': 'すぐに見積もりを取得',
  'app.settings.basic.online_cutting_header': 'ワンストップ鋼板切断カスタムサービス',
  'app.settings.basic.header_desc': 'Yunqie OnlineはAIインテリジェントアルゴリズムを加工見積もりに導入し、これまでにない素晴らしい体験を提供します。',
  'app.settings.basic.report_price': '  2つのステップのみで、3秒以内に見積もりを完了します',
  'app.settings.basic.tip_title': '部品図面をアップロード',
  'app.settings.basic.tip_title1': 'dwg、dxf形式のファイルをサポート',
  'app.settings.basic.tip_title2': 'すべてのアップロードは安全かつ秘密です',
  'app.settings.basic.text1_times': '2019年から現在まで',
  'app.settings.basic.model_factory_footer_text2': 'Yunqie Onlineは鋼板の利用率を向上させて共有節約',
  'app.settings.basic.model_factory_footer_text3': 'トンの鋼板',
  'app.settings.basic.model_factory_footer_text4': '削減された炭素排出量は相当',
  'app.settings.basic.model_factory_footer_text5': 'エーカーの森林、1年のCO',
  'app.settings.basic.model_factory_footer_text6': '吸収量',
  'app.settings.basic.model_factory_footer_phone_text': 'お問い合わせホットライン',
  'app.settings.basic.model_factory_footer_email': 'メール: ',
  'app.settings.basic.model_factory_footer_address': '住所: 浙江省嘉興市秀洲区秀園路北科建創新園42棟102-2',


  'app.settings.basic.modelTitle': 'プラットフォームの利点',
  'app.settings.basic.operationFlow': 'ビジネスプロセス',
  'app.settings.basic.operationFlowText': 'AI インテリジェント アルゴリズムを機械加工見積に導入することで、これまでにない経験がもたらされます。',
  'app.settings.basic.operationFlowText1': 'たった 2 つのステップで 3 秒以内に見積もりを完了できます',

  'app.settings.basic.modelFactory': 'モデルファクトリー',
  'app.settings.basic.enterpriseHonor': '企業の名誉',
  'app.settings.basic.enterpriseValues': '企業価値',

  'app.settings.basic.onlineConsultation': 'オンライン相談',
  'app.settings.basic.zaixiankefu': 'オンラインサービス',
  'app.settings.basic.xiaochengxu': 'アプレット',
  'app.settings.basic.gongzhonghao': '一般公開なし',
  'app.settings.basic.huidaodingbu': 'トップに戻る',


  'app.settings.basic.aboutUs': '私たちについて',
  'app.settings.basic.contactUs': 'お問い合わせ',
  'app.settings.basic.customerService': 'カスタマーサービスに連絡',
  'app.settings.basic.collaborativeRecruitment': '協力採用',
  'app.settings.basic.privacyPolicy': 'プライバシーポリシー',
  'app.settings.basic.telephone': 'Contact Telephone',
  'app.settings.basic.contactAddress': 'Contact Address: Building 42, 102-2, Kejian Innovation Park, Xiuyuan Road North, Xiuzhou District, Jiaxing City, Zhejiang Province',
  'app.settings.basic.weChatCustomerService': 'WeChatカスタマーサービス',
  'app.settings.basic.weChatMiniProgram': 'WeChatミニプログラム',
  'app.settings.basic.weChatofficialAccount': 'WeChat公式アカウント',

  //询价页面（未登录）
  'app.settings.inquiry.modelTitle': '引合情報',//询价信息
  'app.settings.inquiry.demonstrate': '見積依頼手順のデモ',
  'app.settings.inquiry.receivingArea': '受取エリアです',
  'app.settings.inquiry.selectArea': '収穫エリアを選択してください',
  'app.settings.inquiry.partsInformation': '部品情報',

  //询价步骤演示
  'app.settings.inquiry.improvePartInfo': '図面を枠で選択し、部品情報を完全にする',//框选图纸并完善零件信息
  'app.settings.inquiry.jumpToFormal': 'ログインして正式な見積もりにジャンプ',
  'app.settings.inquiry.nextStep': '次のステップ',//下一步
  'app.settings.inquiry.previousStep': '前へ',//上一步
  'app.settings.inquiry.skip': 'スキップ',//跳过

  'app.settings.inquiry.complete': '完了',//完成
  'app.settings.inquiry.uploadDrawings': '図面をアップロード',//上传图纸
  'app.settings.inquiry.completePartInfo': '部品情報を補完してください',//'请完善零件信息'
  'app.settings.inquiry.providesMaterials': 'クラウドカット提供材料',
  'app.settings.inquiry.cuttingPrograms': 'クラウドカット作成切断プログラム',
  //底部栏
  'app.settings.inquiry.totalPrice': '合計価格',
  'app.settings.inquiry.includingTaxes': '(税金を含む)',
  'app.settings.inquiry.quotationAmount': '(見積部品の金額のみを含む)',

  'app.settings.inquiry.jumpInquiry': 'ジャンプ正式引合',//询价
  'app.settings.inquiry.bulkInquiry': '一括引合のジャンプ',//批量询价
  'app.settings.inquiry.dragUpload': 'パーツ図面をクリックまたはドラッグしてアップロードする',
  'app.settings.inquiry.supportsFormats': 'dwg、dxfフォーマットをサポートする',
  'app.settings.inquiry.verificationPassed': '検証に合格しました',

  'app.settings.inquiry.effectiveArea': '有効領域',
  'app.settings.inquiry.selectionDemo': '枠選択図面のデモ',
  'app.settings.inquiry.largeImage': '図面の表示',
  'app.settings.inquiry.replaceDrawing': 'ここをクリックして図面を交換してください',
  'app.settings.inquiry.parsingFailed': '解析に失敗しました',

  'app.settings.inquiry.proportion': '図面:実物',
  'app.settings.inquiry.drawingScale': '図面尺度',
  //询价提示、、图纸上传失败提示
  'app.settings.inquiry.uploadFailed': '図面のアップロードに失敗しました',//上传图纸失败
  'app.settings.inquiry.uploadFailedTip': 'ファイル名が要件を満たしていない、またはファイルタイプが要件を満たしていない、dwg、dxf形式の部品図面をアップロードしてください',
  'app.settings.inquiry.service': 'カスタマーサービス電話',//客服电话
  'app.settings.inquiry.parsingFailedTip': 'シート解析に失敗したか、見積に失敗しました。手動見積に移動しました',
  'app.settings.inquiry.inquiryFailed': '引合に失敗しました',

  'app.settings.inquiry.noDrawing': '図面をアップロードしてください',
  'app.settings.inquiry.addFailed': '新規失敗',
  'app.settings.inquiry.restore': '最後に入力した内容を復元するかどうか',
  'app.settings.inquiry.completeInfo': '情報を改善してください',

  //零件信息（标题）
  'app.settings.parts.thumbnail': 'サムネイル',
  'app.settings.parts.baseInfo': '基礎情報',//基础信息
  'app.settings.parts.materialInfo': 'マテリアル情報',
  'app.settings.parts.priceInfo': '価格情報',
  'app.settings.parts.postProcess': '後工程',
  'app.settings.parts.specifications': '仕様情報',
  'app.settings.parts.specialRequest': '特別な要件',
  'app.settings.parts.createTime': '作成時間',
  'app.settings.parts.notes': 'コメント',
  //基础信息
  'app.settings.parts.drawingNoAndVersion': '図番号-バージョン番号',//图号-版本号
  'app.settings.parts.drawingNo': '図番号',
  'app.settings.parts.version': 'バージョン番号',
  'app.settings.parts.customerVersion': '顧客バージョン番号',//客户版本号
  'app.settings.parts.partsName': 'の名前をあげる',

  'app.settings.parts.outerContour': '矩形外形',
  'app.settings.parts.validity': '有効期間',//有效期
  'app.settings.parts.numberOfParts': '数量',
  'app.settings.parts.effectiveTime': '見積もりを含む当日、最終営業日の24時まで',
  //单位
  'app.settings.parts.piece': '件',
  'app.settings.parts.piece1': '個',
  //材质信息
  'app.settings.parts.cuttingProcess': '切断プロセス',//切割方式
  'app.settings.parts.materialQuality': 'マテリアル',//材质
  'app.settings.parts.plateThickness': '板厚',
  'app.settings.parts.steelMill': '製鉄所',
  //价格信息
  'app.settings.parts.materialExpense': '材料費',
  'app.settings.parts.processingExpense': '加工費',
  'app.settings.parts.unitPrice': '単価',
  'app.settings.parts.totalPrice': '合計価格',
  'app.settings.parts.priceDetails': '材料及び加工費を含む',

  'app.settings.parts.includingMP': '詳細を含む',
  'app.settings.parts.includingTax': '税込み単価',
  'app.settings.parts.taxRate': '税率',
  'app.settings.parts.discount': '割引',
  'app.settings.parts.discountTip': '割引情報のヒント',

  'app.settings.parts.expired': '期限切れ',
  //规格信息
  'app.settings.parts.perimeter': 'しゅうちょう',
  'app.settings.parts.holes': 'せん孔',
  'app.settings.parts.area': 'エリア',
  'app.settings.parts.weight': 'じゅうりょう',
  'app.settings.parts.aspect': '幅×長さ',
  //切割工艺
  'app.settings.parts.laserCutting': 'レーザ切断',
  'app.settings.parts.flameCutting':  'かえんせつだん',
  'app.settings.parts.plasmaCutting': 'プラズマ切断',

  //零件信息输入提示
  'app.settings.parts.enterRequireTip': '特殊需要を入力してください',
  'app.settings.parts.enterRemarksTip': '100文字以内で入力可能',
  'app.settings.parts.enterDrawingNoTip': '図番号を入力してください',//请输入图号
  'app.settings.parts.enterVersionTip': 'バージョン番号を入力してください',//请输入版本号
  'app.settings.parts.enterNameTip': '名前を入力してください',

  'app.settings.parts.existence': '図番号-バージョン番号はすでに存在します',
  'app.settings.parts.enterDVTip': '図番号とバージョン番号を入力してください',
  //操作
  'app.settings.operation.operation': '操作',
  'app.settings.operation.delete': '削除＃サクジョ＃',
  'app.settings.operation.add': '追加',
  'app.settings.operation.modify': '変更',
  'app.settings.operation.yes': 'はい',//是
  'app.settings.operation.no': 'いいえ',//否

  'app.settings.operation.confirm': '確認',//确认
  'app.settings.operation.cancel': 'キャンセル',//取消

  //地址
  'app.settings.address.Tokyo': '東京',
  'app.settings.address.Osaka': '大阪',

  //信息提示
  'app.settings.common.success': 'アップロードに成功しました',//上传成功
  'app.settings.common.failed': 'アップロードに失敗しました',//上传失败
  'app.settings.common.reUpload': 'ファイルのアップロードに失敗しました。再アップロードしてください',//上传文件失败，请重新上传

};
